import { isObject } from '@/utils/is'
import Vue from 'vue'
import VueRouter, { NavigationGuardNext, RawLocation, Route, RouteConfig, RouteMeta } from 'vue-router'
import { dashboardRoutes } from './dashboard'
import { projectRoutes } from './project'
import { memberRoutes } from './member'
import { accountRoutes } from './account'
import { observerRoutes } from './observer'
import { userCheckLoginApi } from '@/api/user'
import { useProjectStore } from '@/pinia/modules/project'
import { passportRoutes } from './passport'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: { name: 'ProjectList' },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '*',
        redirect: '/404'
    },
    {
        path: '/404',
        component: () => import('@/views/common/PageNotFound.vue')
    },
    {
        path: '/d3wordcloud',
        component: () => import('@/views/common/D3wordcloud.vue')
    },
    ...dashboardRoutes,
    ...projectRoutes,
    ...memberRoutes,
    ...accountRoutes,
    ...passportRoutes,
    ...observerRoutes
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
})

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
    const toMeta: RouteMeta = to.meta!


    document.title = (toMeta.title as string) || 'KANTAR ART'
    handleProjectInfo(to, from)

    await checkLogin(to, next)
    next()
})


async function checkLogin(to: Route, next: NavigationGuardNext) {
    const toMeta: RouteMeta = to.meta!
    if (toMeta.requiresAuth) {
        try {
            await userCheckLoginApi()
        } catch (e) {
            console.error(`checkLogin: error: ${String(e)}`)
            next({
                name: 'Login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    }
}


const handleProjectInfo = (to: Route, from: Route) => {
    const rojectStore = useProjectStore()
    if (to.params.projectUuId && to.params.projectUuId !== from?.params?.projectUuId) {
        rojectStore.getProjectInfo(to.params.projectUuId)
    } else if (!to.params.projectUuId) {

        rojectStore.resetProjectInfo()
    }
}

router.afterEach((to: Route, from: Route) => {
    window.scrollTo(0, 0)
})

/**
 * 重写路由的push方法
 */
const routerPush: any = VueRouter.prototype.push
VueRouter.prototype.push = function push(location: any) {
    return routerPush.call(this, location).catch((error: RawLocation) => error)
}
/**
 * 重写路由的replace方法
 */
const routerReplace: any = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location: RawLocation) {
    return routerReplace.call(this, location).catch((error: any) => error)
}

export default router
