



























import { defineComponent } from '@vue/composition-api'
import Avatar from '@/components/Avatar.vue'
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
import { useUserStore } from '@/pinia/modules/user'
import { storeToRefs } from 'pinia'
import { passportLogout } from '@/passport/api'
import { parseJwtToken } from '@/utils/jwt'
import { UserInfo } from '@/api/user/model'

export default defineComponent({
    components: {
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        Avatar
    },
    setup(props, { root }) {
        const userStore = useUserStore()
        const { userInfo } = storeToRefs(userStore)

        const handleCommand = (command: string) => {
            switch (command) {
                case 'accountEdit':
                    return accountEdit()
                case 'passwordUpdate':
                    return passwordUpdate()
                case 'userLogout':
                    return userLogout()
            }
        }

        const accountEdit = () => {
            root.$router.push({ name: 'AccountEdit' })
        }

        const passwordUpdate = () => {
            root.$router.push({ name: 'PasswordUpdate' })
        }

        const userLogout = async () => {
            const info = userStore.userInfo as UserInfo
            if (!info || !info.token) {
                return
            }
            const tokenInfo = parseJwtToken(info.token)
            if (tokenInfo.p === 1) {
                passportLogout()
            } else {
                await userStore.logout()
            }
        }

        return {
            userStore,
            userInfo,
            handleCommand,
        }
    }
})
