import { RouteConfig } from 'vue-router'
import Layout from '@/views/layout/Index.vue'

export const memberRoutes: RouteConfig[] = [
    {
        path: '/member',
        component: Layout,
        children: [
            {
                path: 'edit/:userId?',
                name: 'MemberEdit',
                meta: {
                    activeName: 'MemberList', 
                    requiresAuth: true
                },
                component: () => import('@/views/member/MemberEdit.vue')
            }
        ]
    }
]
