/**
 * 配置信息
 */

import { LocaleType } from '@/interface/common'

const config = {
    apiServer: process.env.VUE_APP_BASEURL || 'https://localhost',
    h5Server: process.env.VUE_APP_H5_SERVER || 'https://localhost/h5/#',
    qiniuDomain: process.env.VUE_APP_QINIU_DOMAIN || '//qiniu.com',
    locale: LocaleType.en_US
}

export default config
