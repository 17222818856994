















import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
    props: {
        size: {
            type: String,
            default: 'default'
        }
    },
    setup(props) {
        return {}
    }
})
