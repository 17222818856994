import { defineStore } from 'pinia'
import { pinia } from '@/pinia'
import _ from 'lodash'
import router from '@/router'
import { UserInfo } from '@/api/user/model'
import { showError } from '../../utils/common'
import { getUserInfoApi } from '@/api/user'

interface UserState {
    userInfo: UserInfo
}

export const useUserStore = defineStore({
    id: 'user',
    state: (): UserState => ({
        userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')!) : {}
    }),
    getters: {
        getUserInfo(): UserInfo {
            return !_.isEmpty(this.userInfo) || localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')!) : {}
        }
    },
    actions: {
        setUserInfo(info: UserInfo) {
            this.userInfo = info
            localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
        },
        async updateUserInfo() {
            try {
                const data = await getUserInfoApi()
                this.setUserInfo({
                    token: this.userInfo.token,
                    ...data
                })
            } catch (error) {
                showError(error)
            }
        },
        resetState() {
            this.userInfo = {} as UserInfo
        },
        async logout() {
            this.resetState()
            localStorage.removeItem('userInfo')
            router.replace({
                name: 'Login',
                query: {
                    redirect: router.currentRoute.fullPath
                }
            })
        }
    }
})

// Need to be used outside the setup
export function useUserStoreWithOut() {
    return useUserStore(pinia)
}
