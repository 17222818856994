import { RouteConfig } from 'vue-router'
import Layout from '@/views/layout/Index.vue'

export const accountRoutes: RouteConfig[] = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/account/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/account/Register.vue')
    },
    {
        path: '/password/find',
        name: 'PasswordFind',
        component: () => import('@/views/account/PasswordFind.vue')
    },
    {
        path: '/password/reset',
        name: 'PasswordReset',
        component: () => import('@/views/account/PasswordReset.vue')
    },
    {
        path: '/password/update',
        name: 'PasswordUpdate',
        component: () => import('@/views/account/PasswordUpdate.vue')
    },
    {
        path: '/account',
        component: Layout,
        children: [
            {
                path: 'edit',
                name: 'AccountEdit',
                meta: {
                    requiresAuth: true
                },
                component: () => import('@/views/account/AccountEdit.vue')
            }
        ]
    }
]
