import { LocaleType } from '@/interface/common'
import i18n from '@/locale'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js'

dayjs.extend(utc);
dayjs.extend(timezone);

export function padZero(num: number, targetLength?: number) {
    if (targetLength === void 0) {
        targetLength = 2
    }

    let str = num + ''

    while (str.length < targetLength) {
        str = '0' + str
    }

    return str
}

const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR

export function parseTimeData(time: number) {
    const days = Math.floor(time / DAY)
    const hours = Math.floor(time % DAY / HOUR)
    const minutes = Math.floor(time % HOUR / MINUTE)
    const seconds = Math.floor(time % MINUTE / SECOND)
    const milliseconds = Math.floor(time % SECOND)
    return {
        days,
        hours,
        minutes,
        seconds,
        milliseconds
    }
}


export const timeFormat = (time: number, format: string = 'HH:mm:ss') => {
    const timeData = parseTimeData(time)
    const days = timeData.days
    let hours = timeData.hours
    let minutes = timeData.minutes
    let seconds = timeData.seconds
    let milliseconds = timeData.milliseconds

    if (format.indexOf('DD') === -1) {
        hours += days * 24
    } else {
        format = format.replace('DD', padZero(days))
    }

    if (format.indexOf('HH') === -1) {
        minutes += hours * 60
    } else {
        format = format.replace('HH', padZero(hours))
    }

    if (format.indexOf('mm') === -1) {
        seconds += minutes * 60
    } else {
        format = format.replace('mm', padZero(minutes))
    }

    if (format.indexOf('ss') === -1) {
        milliseconds += seconds * 1000
    } else {
        format = format.replace('ss', padZero(seconds))
    }

    if (format.indexOf('S') !== -1) {
        const ms = padZero(milliseconds, 3)

        if (format.indexOf('SSS') !== -1) {
            format = format.replace('SSS', ms)
        } else if (format.indexOf('SS') !== -1) {
            format = format.replace('SS', ms.slice(0, 2))
        } else {
            format = format.replace('S', ms.charAt(0))
        }
    }

    return format
}


/**
 * 将秒数换成时分秒格式
 * @param value
 * @returns
 */
export const timeFormatStr = (value: number, format?: string) => {
    if (!value) {
        return ''
    }
    format = format || 'HHmmss'
    let secondTime = ~~value
    let minuteTime = 0
    let hourTime = 0
    if (secondTime > 60) {

        minuteTime = ~~(secondTime / 60)

        secondTime = ~~(secondTime % 60)

        if (minuteTime >= 60) {

            hourTime = ~~(minuteTime / 60)

            minuteTime = ~~(minuteTime % 60)
        }
    }
    let result = ''
    const space = i18n.locale == LocaleType.en_US ? ' ' : ''

    if (format.indexOf('ss') !== -1) {
        result = secondTime > 0 ? ~~(secondTime) + i18n.t('seconds').toString() : ''
    }

    if (format.indexOf('mm') !== -1) {
        result = (minuteTime > 0 ? ~~(minuteTime) + (result ? i18n.t('min').toString() + space : i18n.t('mins').toString()) + space : '') + result
    }

    if (format.indexOf('HH') !== -1) {
        result = hourTime > 0 ? ~~(hourTime) + i18n.tc('hour', hourTime) + space : '' + result
    }

    return result
}

/**
 * 将秒数换成时分秒格式(多语言特殊处理)
 * @param value
 * @param format
 */
export const timeFormatLocaleStr = (value: number, format?: string) => {
    if (!value) {
        return ''
    }
    format = format || 'HHmmss'
    let secondTime = ~~value
    let minuteTime = 0
    let hourTime = 0
    if (secondTime > 60) {

        minuteTime = ~~(secondTime / 60)

        secondTime = ~~(secondTime % 60)

        if (minuteTime >= 60) {

            hourTime = ~~(minuteTime / 60)

            minuteTime = ~~(minuteTime % 60)
        }
    }
    let result = ''
    const space = i18n.locale == LocaleType.en_US ? ' ' : ''
    if (i18n.locale == LocaleType.en_US) {
        result = timeFormat(value * 1000, 'mm:ss')
    } else {
        if (format.indexOf('ss') !== -1) {
            result = secondTime > 0 ? ~~(secondTime) + i18n.t('seconds').toString() : ''
        }

        if (format.indexOf('mm') !== -1) {
            result = (minuteTime > 0 ? ~~(minuteTime) + (result ? i18n.t('min').toString() + space : i18n.t('mins').toString()) + space : '') + result
        }

        if (format.indexOf('HH') !== -1) {
            result = hourTime > 0 ? ~~(hourTime) + i18n.tc('hour', hourTime) + space : '' + result
        }
    }
    return result
}

/**
 * 将时间转换为指定时区的ISO8601字符串
 * @param time
 * @param tz
 */
export const localToISO = (time: string, tz: string) => {
    return dayjs.tz(time, tz).toISOString();
}

/**
 * 将ISO8601字符串转换为指定时区不带时区信息的字符串
 * @param iso
 * @param tz
 */
export const isoToLocal = (iso: string, tz: string) => {
    return dayjs(iso).tz(tz).format('YYYY-MM-DDTHH:mm:ss');
}

/**
 * 返回当前时区
 */
export const currentTimezone = () => {
    return dayjs.tz.guess();
}

/**
 * 将ISO8601字符串转换为指定时区的格式化字符串
 * @param iso
 * @param tz
 * @param format
 * @returns
 */
export const isoToString = (iso: string, tz: string, format: string) => {    
    return dayjs(iso).tz(tz).format(format);
}

export const  getTzText=(tz: string) =>{
    const offset = dayjs().tz(tz).format('Z');
    const sign = offset[0] === '-' ? '+' : '-';
    const hours = offset.slice(1, 3);
    const minutes = offset.slice(4, 6);
    return `(UTC${sign}${hours}:${minutes})`;
  }
