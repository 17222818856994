


































import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import { dashboardMenu, MenuInfoItem } from '@/utils/menus'
import Logo from '@/components/Logo.vue'
import User from '@/components/User.vue'
import { useUserStore } from '@/pinia/modules/user'
import { storeToRefs } from 'pinia'
import { UserRole } from '@/api/user/model'
import { openUrl } from '@/utils/common'
import LocaleSelect from '@/components/LocaleSelect.vue'
export default defineComponent({
    components: {
        Logo,
        User,
        LocaleSelect
    },
    setup(props, { root }) {
        const userStore = useUserStore()
        const { userInfo } = storeToRefs(userStore)
        const menuList = ref()
        if (UserRole.Super == userInfo.value.role) {
            menuList.value = dashboardMenu.filter((v) => v.routeName != 'ProjectAssistant')
        } else if (UserRole.User == userInfo.value.role) {
            menuList.value = dashboardMenu.filter((v) => v.routeName != 'MemberList')
        } else {
            menuList.value = dashboardMenu
        }

        const activeMenu = computed(() => root.$route.name)
        const moduleName = computed(() => root.$route.path.split('/')[1])
        watch(
            () => activeMenu.value,
            () => handleMenu()
        )
        const activeName = computed(() => {
            const { activeName } = root.$route.meta!
            return activeName
        })

        const goPath = (item: MenuInfoItem) => {
            if (item.childs && item.childs.length > 0) {
                item.showChilds = !item.showChilds
                return
            }
            if (item.url) {
                openUrl(item.url)
                return
            }
            if (item.routeName == activeMenu.value) {
                return
            }
            root.$router.push({
                name: item.routeName
            })
        }

        const handleMenu = () => {}
        handleMenu()

        return {
            activeName,
            menuList,
            activeMenu,
            moduleName,
            goPath
        }
    }
})
