import axios from 'axios'
import { useUserStore } from '@/pinia/modules/user'
import { useSettingStore } from '@/pinia/modules/setting'

const Axios = axios.create({
    responseType: 'json',
    withCredentials: false,
    timeout: 600000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
})

Axios.interceptors.request.use((config: any) => {
    const userStore = useUserStore()
    const settingStore = useSettingStore()
    const { token } = userStore.userInfo
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    config.headers['Accept-Language'] = settingStore.locale
    return config
})

Axios.interceptors.response.use((res) => {
    if (typeof res.data === 'string' || res.data instanceof Blob) {
        return res.data
    }
    if (res.data.code !== 0) {
        const err = {
            statusCode: res.data.code,
            errMsg: res.data.msg
        }
        return Promise.reject(err)
    }
    return res.data.data
}, (error) => {
    const userStore = useUserStore()
    if (error.response) {
        if (error.response.status === 401) {
            userStore.logout()
            const err = {
                statusCode: error.response.status,
                errMsg: error.response.data?.msg || error.request.statusText
            }
            return Promise.reject(err)
        } else if (error.response.data?.code) {
            const err = {
                statusCode: error.response.data.code,
                errMsg: error.response.data.msg
            }
            return Promise.reject(err)
        } else {
            const err = {
                statusCode: error.response.status,
                errMsg: `${error.request.status}-${error.request.statusText}`
            }
            return Promise.reject(err)
        }
    } else {
        return Promise.reject(error)
    }
})

export default Axios
