import Vue from 'vue'
import VueI18n from 'vue-i18n'
import element_en_US from 'element-ui/lib/locale/lang/en'
import element_zh_CN from 'element-ui/lib/locale/lang/zh-CN'
import element_zh_TW from 'element-ui/lib/locale/lang/zh-TW'
import ElementLocale from 'element-ui/lib/locale'
import en_US from './modules/en-US.json'
import zh_CN from './modules/zh-CN.json'
import zh_TW from './modules/zh-TW.json'
import config from '@/config'
Vue.use(VueI18n)
console.log(config.locale,localStorage.getItem('locale') )
const i18n = new VueI18n({
    locale: localStorage.getItem('locale') || config.locale,
    messages: {
        'en': {
            ...element_en_US, ...en_US
        },
        'zh-CN': {
            ...element_zh_CN, ...zh_CN
        },
        'zh-TW': {
            ...element_zh_TW, ...zh_TW
        }
    }
})


ElementLocale.i18n((key: string, value: string) => i18n.t(key, value))

export default i18n
