import Vue, { CreateElement } from 'vue'
import 'babel-polyfill'
import VueCompositionApi from '@vue/composition-api'
import VueWechatTitle from 'vue-wechat-title'
import App from './App.vue'
import router from './router'
import { pinia } from './pinia'
import i18n from './locale'
import 'animate.css'
import 'remixicon/fonts/remixicon.css'
import 'loaders.css/loaders.min.css'
import '@/assets/font/font.scss'
import './style/common.scss'
import './style/tool.scss'
import './utils/common'
import './utils/element'
import './utils/array'
import './utils/markdownToHtml'

Vue.use(VueWechatTitle)
Vue.use(VueCompositionApi)

Vue.config.productionTip = false

const addStatScript = () => {
    const scriptUrl = process.env.VUE_APP_PINGDOM_SCRIPT_URL;
    if (scriptUrl) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.async = true;
        document.body.appendChild(script); 
    }
};
new Vue({
    router,
    pinia,
    i18n,
    render: (h: CreateElement) => h(App)
}).$mount('#app')

document.addEventListener('DOMContentLoaded', addStatScript);