import qs from 'qs'
import Axios from '../index'
import { IdDto } from '../model'
import { CreateUserDto, EditUserDto, LoginDto, ResetPasswordDto, UpdatePasswordDto, UpdateUserDto, UserQueryDto } from './model'


/**
 * 用户登录
 * @param {*} option
 */
export function userLoginApi(option: LoginDto): Promise<any> {
    return Axios.post(`/api/v1/user/login`, option)
}

/**
 * 登录状态校验
 */
export function userCheckLoginApi(): Promise<any> {
    return Axios.get(`/api/v1/user/status`)
}

/**
 * 用户管理分页列表
 *
 * @param option
 * @returns
 */
export function getMemberListApi(option: UserQueryDto): Promise<any> {
    return Axios.get(`/api/v1/user/members?${qs.stringify(option)}`)
}

/**
 * 当前登录用户信息
 */
export function getUserInfoApi(): Promise<any> {
    return Axios.get(`/api/v1/user/info`)
}

/**
 * 删除用户
 */
export function deleteUserApi(option: IdDto): Promise<any> {
    return Axios.delete(`/api/v1/user/${option.id}`)
}

/**
 * 启用用户
 */
export function enableUserApi(option: IdDto): Promise<any> {
    return Axios.put(`/api/v1/user/${option.id}/enable`)
}

/**
 * 禁用用户
 */
export function disableUserApi(option: IdDto): Promise<any> {
    return Axios.put(`/api/v1/user/${option.id}/disable`)
}

/**
 * 创建用户
 * @param {*} option
 */
export function createUserApi(option: CreateUserDto): Promise<any> {
    return Axios.post(`/api/v1/user/create`, option)
}

/**
 * 更新用户信息(当前登录用户)
 * @param {*} option
 */
export function editUserApi(option: EditUserDto): Promise<any> {
    return Axios.post(`/api/v1/user/edit`, option)
}

/**
 * 更新用户信息(编辑系统用户信息)
 * @param option
 * @returns
 */
export function updateUserApi(option: UpdateUserDto): Promise<any> {
    return Axios.post(`/api/v1/user/admin-edit`, option)
}

/**
 * 找回密码-发送到指定的邮箱
 * @param {*} option
 */
export function findPasswordApi(option: { email: string }): Promise<any> {
    return Axios.post(`/api/v1/user/find-password`, option)
}

/**
 * 找回密码-重置密码
 * @param {*} option
 */
export function resetPasswordApi(option: ResetPasswordDto): Promise<any> {
    return Axios.post(`/api/v1/user/reset-password`, option)
}

/**
 * 找回密码-修改密码
 * @param {*} option
 */
export function updatePasswordApi(option: UpdatePasswordDto): Promise<any> {
    return Axios.post(`/api/v1/user/update-password`, option)
}

/**
 * 创建用户-重新发送邮件
 * @param {*} option
 */
export function resentUserApi(option: { email: string }): Promise<any> {
    return Axios.post(`/api/v1/user/resent`, option)
}
