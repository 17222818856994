import { QueryPage } from '../model'
import i18n from '@/locale'

export interface LoginDto {
    username: string
    password: string
}

export interface UserInfo {
    token?: string
    email?: string
    id?: number
    nickname?: string
    photo?: string
    role?: UserRole
    status?: UserStatus
    passport_only?: boolean
}

export interface UserQueryDto extends QueryPage {
    filters?: any
}

export enum UserStatus {
    Disabled = -1,
    NotActive = 0,
    Active = 1
}

export enum UserSource {
    Art = 'art',
    Passport = 'passport',
}

export enum UserSourceName {
    art = <any>i18n.t('userSource.art'),
    passport = <any>i18n.t('userSource.passport'),
}

export enum UserRole {
    Super = 'super',
    Admin = 'admin',
    User = 'user'
}

export enum UserRoleName {
    super = <any>i18n.t('userRole.super'),
    admin = <any>i18n.t('userRole.admin'),
    user = <any>i18n.t('userRole.user')
}

export interface UserRoleOption {
    label: UserRoleName
    value: UserRole
}

export interface EditUserDto {
    email: string
    nickname: string
    photo: string
}

export interface UpdateUserDto {
    nickname: string
    photo?: string
    role: string
    user_id: string
}

export interface CreateUserDto {
    email: string
    nickname: string
    role?: UserRole
    [key: string]: any
}

export interface ResetPasswordDto {
    email: string
    hash_key: string
    password: string
}


export interface UpdatePasswordDto {
    new_password: string
    password: string
}
