import config from '@/config'

const localeFromStorage = localStorage.getItem('locale');
const processedLocale = (localeFromStorage !== null)
  ? localeFromStorage.replace("-", "_")
  : (config.locale ?? '').replace("-", "_");

export const FAQ_URL = 'https://ktglbuc.sharepoint.com/:w:/s/MarketplaceGreaterChina/EUNKGR1JNMFIk3VHiBkfrhwB_YV63ujY3eS3PEu5eNEtYQ'

export const FAQ_ENGLISH_URL = 'https://ktglbuc.sharepoint.com/:w:/r/sites/MarketplaceGreaterChina/Shared%20Documents/%F0%9F%92%8EART%20Tookit%20Hub/2%20Execution%20Manual/ART%20FAQ-%20English.docx?d=w5819c52c4acd4110b28f1bfabca164f3&csf=1&web=1&e=7bP7en'

export const OPERATIONAL_MANUAL_URL = 'https://ktglbuc.sharepoint.com/:w:/s/MarketplaceGreaterChina/EXAm2klaljNEil06zqWm7ScBiWbVUdGw5X1pZn961rOTOQ'

export const OPERATIONAL_MANUAL_URL_ENGLISH = 'https://ktglbuc.sharepoint.com/:w:/s/MarketplaceGreaterChina/EVgVlgpZig9OltX0UEAdXBUBQHbOCkaBJRoCQkpx8QF_ww'
export const OUTLINE_IMPORT_TEMPLATE_URL = process.env[`VUE_APP_QINIU_PATH`]+'template/guide_excel_import_template_'+processedLocale+'.xlsx'
export const PARTICIPANTS_IMPORT_TEMPLATE_URL = process.env[`VUE_APP_QINIU_PATH`]+'template/Template_for_Importing_List_of_participants_'+processedLocale+'.xlsx'

