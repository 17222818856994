export class PassportState {

    static generateState(key: string): string {
        const state = this.createRandomString()
        sessionStorage.setItem(key, state)
        return state;
    }

    static validateState(key: string, returnedState: string): boolean {
        const storedState = sessionStorage.getItem(key)
        return storedState === returnedState
    }

    static clearState(key: string): void {
        sessionStorage.removeItem(key)
    }

    private static createRandomString(): string {
        return [...Array(30)].map(() => Math.random().toString(36)[2]).join('')
    }
}
