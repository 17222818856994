export interface VerifyCodeMsg {
    counVal: string
    countDown: number
    loading: boolean
}

export enum LocaleType {
    en_US = 'en',
    zh_CN = 'zh-CN',
    zh_TW = 'zh-TW',
    vi_VN = 'vi-VN',
    fr_FR = 'fr-FR',
    es_ES = 'es-ES',
    id_ID = 'id-ID',
    tr_TR='tr-TR',
    th_TH='th-TH',
    ja_JP='ja-JP',
    es_MX='es-MX',
    ms_MY='ms-MY',
    sv_SE='sv-SE'
}