import { RouteConfig } from 'vue-router'

export const passportRoutes: RouteConfig[] = [
    {
        path: '/passport/login',
        name: 'PassportLogin',
        component: () => import('@/views/passport/Login.vue')
    },
    {
        path: '/passport/logout',
        name: 'PassportLogout',
        component: () => import('@/views/passport/Logout.vue')
    },
]
