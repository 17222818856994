import { RouteConfig } from 'vue-router'

export const projectRoutes: RouteConfig[] = [
    {
        path: '/project',
        component: () => import('@/views/project/ProjectLayout.vue'),
        children: [
            {
                path: ':projectUuId?/introduce',
                name: 'ProjectIntroduce',
                meta: {
                    rootName: 'detail',
                    requiresAuth: true
                },
                component: () => import('@/views/project/ProjectIntroduce.vue')
            },
            {
                path: ':projectUuId/time',
                name: 'ProjectTime',
                meta: {
                    rootName: 'detail',
                    requiresAuth: true
                },
                component: () => import('@/views/project/ProjectTime.vue')
            },
            {
                path: ':projectUuId/invite',
                name: 'ProjectInvite',
                meta: {
                    rootName: 'detail',
                    requiresAuth: true
                },
                component: () => import('@/views/project/ProjectInvite.vue')
            },
            {
                path: ':projectUuId/survey',
                name: 'ProjectSurvey',
                meta: {
                    rootName: 'detail',
                    requiresAuth: true
                },
                component: () => import('@/views/project/ProjectSurvey.vue')
            },
            {
                path: ':projectUuId/group',
                name: 'ProjectGroup',
                meta: {
                    rootName: 'detail',
                    requiresAuth: true
                },
                component: () => import('@/views/project/ProjectGroup.vue')
            },
            {
                path: ':projectUuId/quota',
                name: 'ProjectQuota',
                meta: {
                    rootName: 'detail',
                    requiresAuth: true
                },
                component: () => import('@/views/project/ProjectQuota.vue')
            },
            {
                path: ':projectUuId/word-cloud',
                name: 'ProjectWordCloud',
                meta: {
                    rootName: 'detail',
                    requiresAuth: true
                },
                component: () => import('@/views/project/ProjectWordCloud.vue')
            },
            {
                path: ':projectUuId/test-group',
                name: 'ProjectTestGroup',
                meta: {
                    rootName: 'detail',
                    requiresAuth: true
                },
                component: () => import('@/views/project/ProjectTestGroup.vue')
            },
            {
                path: ':projectUuId/live',
                name: 'ProjectLive',
                meta: {
                    requiresAuth: true
                },
                component: () => import('@/views/project/ProjectLive.vue')
            }
        ]
    }
]
