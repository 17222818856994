import dayjs from 'dayjs'

/**
 * 处理数字格式
 * @param num 数字
 * @param limit 保留位数
 * @param isRetain 是否需要保留小数后的0
 */
export const numToFixed = (
    num: string | number,
    limit: number,
    isRetain: boolean = true
) => {
    const number = Number(num).toFixed(limit) as unknown as number
    if (isRetain) { return number }
    return (number * 100) / 100
}

/**
 * 按,分割数字
 * @param num
 */
export const numPartition = (num: string | number) => {
    if (!num) { return 0 }
    return num.toString().replace(/\d{1,3}(?=(\d{3})+(\.|$))/g, '$&,')
}

/**
 * 日期格式化
 * @param date 日期对象，或日期的ISO格式字符串
 * @param format 格式定义，参考dayjs的格式定义
 * @returns
 */
export const dateFormat = (date: string | Date, format = 'YYYY-MM-DD HH:mm'): string => {
    if (!date) { return '' }
    return dayjs(date).format(format)
}
