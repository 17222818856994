import { RouteConfig } from 'vue-router'

import Layout from '@/views/layout/Index.vue'

export const dashboardRoutes: RouteConfig[] = [
    {
        path: '/dashboard',
        component: Layout,
        redirect: { name: 'Home' },
        children: [
            {
                path: 'home',
                name: 'Home',
                meta: {
                    requiresAuth: true
                },
                component: () => import('@/views/home/Index.vue')
            },
            {
                path: 'project/list',
                name: 'ProjectList',
                meta: {
                    requiresAuth: true
                },
                component: () => import('@/views/project/ProjectList.vue')
            },
            {
                path: 'project/recycle',
                name: 'ProjectRecycle',
                meta: {
                    requiresAuth: true
                },
                component: () => import('@/views/project/ProjectRecycle.vue')
            },
            {
                path: 'project/assistant',
                name: 'ProjectAssistant',
                meta: {
                    requiresAuth: true
                },
                component: () => import('@/views/project/ProjectAssistant.vue')
            },
            {
                path: 'member/list',
                name: 'MemberList',
                meta: {
                    requiresAuth: true
                },
                component: () => import('@/views/member/MemberList.vue')
            }
        ]
    }
]
