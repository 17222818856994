import { getRootURL, goUrl } from "@/utils/common";
import { PassportState } from "./state";


const LOGIN_STATE_KEY = 'passportLoginState'

export function passportLogin(): void {
    const state = PassportState.generateState(LOGIN_STATE_KEY)

    const params = {
        app_id: process.env.VUE_APP_PASSPORT_APP_ID || '',
        redirect_url: getRootURL() + '/passport/login',
        state: state,
    };

    const url = process.env.VUE_APP_PASSPORT_BASE_URL + '/passport/user_auth.shtml'
    
    goUrl(url, params)
}

export function passportCheckLoginState(state: string): boolean {
    const result = PassportState.validateState(LOGIN_STATE_KEY, state)
    PassportState.clearState(LOGIN_STATE_KEY)
    return result
}


const LOGOUT_STATE_KEY = 'passportLogoutState'

export function passportLogout(): void {
    const state = PassportState.generateState(LOGOUT_STATE_KEY)

    const params = {
        app_id: process.env.VUE_APP_PASSPORT_APP_ID || '',
        redirect_url: getRootURL() + '/passport/logout',
        state: state,
    };

    const url = process.env.VUE_APP_PASSPORT_BASE_URL + '/passport/user_logout.shtml'

    goUrl(url, params)
}

export function passportCheckLogoutState(state: string): boolean {
    const result = PassportState.validateState(LOGOUT_STATE_KEY, state)
    PassportState.clearState(LOGOUT_STATE_KEY)
    return result
}

