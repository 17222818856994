import { DriverDto, UuIdDto } from './../model'
import Axios from '../index'
import { GetMessageOptionsAnalyzeDto, GetMessageSegmentAnalyzeDto ,TestGroupAnalysisDto,IdsDto} from '../project/model'

/**
 * 观察者-分享信息
 * @param {*} option
 */
export function getShareInfoApi(option: UuIdDto): Promise<any> {
    return Axios.get(`/api/v1/observer/${option.uuid}?t=${Date.now()}`)
}

/**
 * 观察者-项目信息
 * @param {*} option
 */
export function getLiveProjectInfoApi(option: UuIdDto): Promise<any> {
    return Axios.get(`/api/v1/observer/live/${option.uuid}`)
}

/**
 * 观察者-会话环节列表
 * @param {*} option
 */
export function getLiveConversationsApi(option: UuIdDto): Promise<any> {
    return Axios.get(`/api/v1/observer/live/${option.uuid}/conversations`)
}

/**
 * 观察者-基础调查列表
 * @param {*} option
 */
export function getLiveOnboardingPollsApi(option: UuIdDto): Promise<any> {
    return Axios.get(`/api/v1/observer/live/${option.uuid}/onboarding-polls`)
}


/**
 * 观察者 分析模块-根据样本分组获取分析结果
 */
export function getLiveMessageSegmentAnalyzeApi(option: GetMessageSegmentAnalyzeDto): Promise<any> {
    return Axios.get(`/api/v1/observer/live/${option.uuid}/message-segment/${option.mid}/segment/${option.id}`, { params: { display: option.display, branch_id: option.branch_id, driver: option.driver,analysis_type:option.analysis_type?option.analysis_type:'' } })
}


/**
 * 观察者 分析模块-根据Filter选项获取分析结果
 */
export function getLiveMessageOptionsAnalyzeApi(option: GetMessageOptionsAnalyzeDto): Promise<any> {
    return Axios.post(`/api/v1/observer/live/${option.uuid}/message-segment/${option.mid}/segment-options?driver=${option.driver ?? ''}`, { options: option.options, branch_id: option.branch_id, display: option.display })
}

/**
 * 观察者 测试组-分析模块
 */
export function getLiveTestGroupMessageAnalyzeApi(option: TestGroupAnalysisDto): Promise<any> {
    return Axios.post(`/api/v1/observer/live/${option.uuid}/test-group-analysis/${option.tgid}`, option)
}

/**
 * 观察者 测试组-详情
 */
export function getLiveTestGroupInfoApi(option: IdsDto): Promise<any> {
    return Axios.get(`/api/v1/observer/live/${option.uuid}/test-group/${option.testGroupId}`)
}



/**
 * 观察者 会话消息列表轮询接口
 *
 * @param option
 * @returns
 */
export function getLiveConversationMessagesApi(option: UuIdDto & DriverDto): Promise<any> {
    return Axios.get(`/api/v1/observer/live/${option.uuid}/messages?driver=${option.driver}`)
}

/**
 * 观察者 受访者列表轮询接口
 *
 * @param option
 * @returns
 */
export function getLiveConversationRespondentsApi(
    option: UuIdDto
  ): Promise<any> {
      return Axios.get(
        `/api/v1/observer/live/${option.uuid}/respondents`
      );
  }


/**
 * 观察者 导出分析数据
 * @param option
 * @returns
 */
export function excelDataExportApi(option: UuIdDto): Promise<any> {
    return Axios.get(`/api/v1/observer/${option.uuid}/conversation-excel`, { responseType: 'blob' })
}


/**
 * 观察者 导出受访者数据
 * @param option
 * @returns
 */
export function excelReplyDataExportApi(option: UuIdDto): Promise<any> {
    return Axios.get(`/api/v1/observer/${option.uuid}/conversation-reply-excel`, { responseType: 'blob' })
}


/**
 * 观察者 获取受访者资源下载信息
 * @param option
 * @returns
 */
export function getReplyDownloadInfoApi(option: UuIdDto): Promise<any> {
    return Axios.get(`/api/v1/observer/${option.uuid}/download-resource`)
}




