import Vue from "vue";
import i18n from "@/locale";
import {
    Button,
    Image,
    Link,
    Icon,
    Message,
    MessageBox,
    InfiniteScroll,
    Loading,
    Dialog,
    Input,
    InputNumber,
    Select,
    Option,
    Notification,
    Tooltip,
    Popover,
} from "element-ui";

import CollapseTransition from "element-ui/lib/transitions/collapse-transition";

Vue.use(Button);
Vue.use(Image);
Vue.use(Link);
Vue.use(InfiniteScroll);
Vue.use(Icon);
Vue.use(Loading);
Vue.use(Dialog);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tooltip);
Vue.use(Popover);
Vue.component(CollapseTransition.name, CollapseTransition);


MessageBox.setDefaults({
    title: i18n.t("caution").toString(),
    confirmButtonText: i18n.t("confirm").toString(),
    cancelButtonText: i18n.t("cancel").toString(),
    showClose: false,
    closeOnPressEscape: false,
});

// @ts-ignore
Dialog.props.closeOnClickModal.default = false;

Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;
