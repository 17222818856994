import Vue from 'vue';
import { Remarkable } from 'remarkable';


// 创建并导出一个函数，该函数返回一个将 Markdown 转换为 HTML 的函数
export function useMarkdownToHtml(): (markdown: string) => string {
  const converter = new Remarkable();
  return function(markdown: string): string {
    return converter.render(markdown);
  };
}

// 将转换函数绑定到 Vue 的原型上，使其成为全局可用的方法
Vue.prototype.$markdownToHtml = useMarkdownToHtml();
