/**
 * 指定位置插入元素
 * @param index 添加元素的位置
 * @param items 向数组添加的新项目
 */
if (!Array.prototype.insert) {
    Array.prototype.insert = function (index, ...items) {
        if (isNaN(index)) {
            throw new TypeError('Please key in numbers')
        }
        this.splice(index, 0, ...items)
    }
}
