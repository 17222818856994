import { UuIdDto } from './../model'
import Axios from '../index'


/**
 * 获取当前项目正在进行的整个访谈信息
 *
 * @param option
 * @returns
 */
export function getConversationInfoApi(option: UuIdDto) {
    return Axios.get(`/api/v1/conversations/${option.uuid}?t=${Date.now()}`)
}
