import Vue from "vue";
import type { VerifyCodeMsg } from "@/interface/common";
import VueClipBoard from "vue-clipboard2";
import router from "@/router";
import cookies from "js-cookie";
import * as filters from "./filters";
import { isObject } from "./is";
import { isArray, debounce } from "lodash";
import { timeFormat } from "./time-format";
import { disabledMessageEntity } from "./disabled-message-entity";
import i18n from "@/locale";
Vue.use(VueClipBoard);


export const primaryColor = "#E3C24C";


export const defaultCover = require("@/assets/image/default-cover.png");

export const defaultAvatar = require("@/assets/image/default-avatar.png");

export const defaultUserAvatar = require("@/assets/image/default-user.png");

/**
 * 处理验证码倒计时
 * @param _this
 */
export const handleVerifyCodeMsg = (_this: VerifyCodeMsg) => {
    if (_this.countDown === 0) {
        _this.counVal = "获取验证码";
        _this.countDown = 60;
        _this.loading = false;
    } else {
        _this.counVal = `请${_this.countDown}s后再获取`;
        _this.countDown--;
        _this.loading = true;
        setTimeout(() => {
            handleVerifyCodeMsg(_this);
        }, 1000);
    }
};

/**
 * /n 转 br
 * @param str
 */
export const handleBr = (str: string) => {
    return str?.replace(/\r?\n/g, "<br/>") || "";
};

/**
 * 复制文本
 */
export const doCopy = async (txt: string) => {
    if (txt) {
        Vue.prototype.$copyText(txt).then(
            (e: any) => {
                Vue.prototype.$message({
                    type: `success`,
                    message: i18n.t("copySuccess"),
                });
            },
            (e: any) => {
                Vue.prototype.$message({
                    type: `error`,
                    message: i18n.t("error"),
                });
            }
        );
    }
};

/**
 *
 * @param value
 * @param ins
 * @returns
 */
export const toFixed = (value: any, ins: number = 2) => {
    if (!value) {
        return 0;
    }
    return Number(value / 100).toFixed(ins);
};

/**
 * 清除所有cookie
 */
export const clearAllCookies = () => {
    const allCookie = cookies.get();
    for (const key in allCookie) {
        if (Object.prototype.hasOwnProperty.call(allCookie, key)) {
            console.log("清除：", key);
            cookies.remove(key, { path: "" });
            cookies.remove(key, { path: "", domain: getMainDomain() });
        }
    }
};

/**
 * 重定向页面，添加参数
 * @param routerObj
 * @param obj
 */
export const replaceRouter = (routerObj: any, obj: any) => {
    const queryMsg = JSON.parse(JSON.stringify(routerObj.query));
    router.replace({
        name: routerObj.name!,
        query: Object.assign(queryMsg, obj),
    });
};

/**
 * 获取当前的一级域名，如果是ip或localhost则直接返回
 */
export function getMainDomain() {
    const domain = document.domain;
    if (domain.match(/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/g)) {
        return domain;
    }
    const r = domain.match(
        /([a-z0-9][a-z0-9\-]*?\.(?:com|cn|net|org|gov|info|la|cc|co|jp)(?:\.(?:cn|jp))?)$/
    );
    if (r) {
        return r[0];
    } else {
        return domain;
    }
}

/**
 * 数字前面补0
 * @param num  传入的数字
 * @param n 需要的字符长度
 */
export function prefixInteger(num: number | string, n: number) {
    return (Array(n).join("0") + num).slice(-n);
}

/**
 * 显示错误的信息
 * @param error
 */
export const showError = debounce((error: any) => {
    Vue.prototype.$message.error(error?.errMsg || error.toString());
}, 100);

/**
 * 对象对比
 * @param obj1
 * @param obj2
 * @returns
 */
export function compareObject(obj1: any, obj2: any): boolean {

    if (!isObject(obj1) || !isObject(obj2)) {
        if (obj1 === obj2) {
            return true;
        } else if (obj1 !== obj2) {
            return false;
        }
    }

    const obj1PropsArr = Object.getOwnPropertyNames(obj1);
    const obj2PropsArr = Object.getOwnPropertyNames(obj2);

    if (obj1PropsArr.length !== obj2PropsArr.length) {
        return false;
    }

    let status = true;
    for (const key of obj1PropsArr) {
        status = compareObject(obj1[key], obj2[key]);


        if (!status) {
            break;
        }
    }

    return status;
}

export const leaveConfirm = (obj1: any, obj2: any) => {
    const isSame = compareObject(
        JSON.parse(JSON.stringify(obj1)),
        JSON.parse(JSON.stringify(obj2))
    );
    return new Promise((resolve, reject) => {
        if (isSame) {
            resolve(true);
        } else {
            Vue.prototype
                .$msgbox({
                    showCancelButton: true,
                    message: "在当前页面还有尚未保存的内容，是否确定跳转到其他页面？<br> 若直接跳转，未保存的内容将不会保留。",
                    dangerouslyUseHTMLString: true,
                    distinguishCancelAndClose: true,
                })
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    reject(false);
                });
        }
    });
};

/**
 * 导出文件
 * @param data
 * @param fileName
 */
export const exportFile = (data: any, fileName: string) => {
    let blob = data;
    if (typeof data === "string") {
        blob = new Blob([data], { type: "" });
    }
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
};


export const getBlob = (url: string) => {
    return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();

        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(xhr.response);
            }
        };
        xhr.send();
    });
};

/**
 * 计算百分比
 */
export const getPercentage = (num: number, total: number) => {
    if (total === 0) {
        return 0;
    }
    return Math.round((num / total) * 100);
};

/**
 * 去除字符串中的html标签
 * @param str
 * @returns
 */
export const removeHTMLTag = (str: string) => {
    str = str.replace(/<\/?[^>]*>/g, "");
    str = str.replace(/[ | ]*\n/g, "\n");
    str = str.replace(/\n[\s| | ]*\r/g, "\n");
    const arrEntities: any = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
    str = str.replace(/&(lt|gt|nbsp|amp|quot);/gi, (all, t) => arrEntities[t]);
    return str;
};

/**
 * 同步setTimeout
 * @param callback
 * @returns
 */
export const asyncSetTimeout = (callback: any) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            callback();
            resolve(true);
        });
    });
};

/**
 * 拼接七牛缩略图
 *
 * @param url 七牛链接
 * @returns
 */
export const getQiniuThumbnail = (url: string, px = 120) => {
    if (url) {
        return url + `?imageView2/0/w/${px}/h/${px}/ignore-error/1`;
    }
    return url;
};

/**
 * 获取视频第一帧缩略图
 *
 * @param url 七牛链接
 * @returns
 */
export const getVideoThumbnail = (url: string) => {
    if (url) {
        return url + "?vframe/png/offset/0/h/120";
    }
    return url;
};

/**
 * 链接跳转
 * @param url
 * @param queryParams
 */
export const goUrl = (url: string, queryParams?: Record<string, string | number | boolean>) => {
    let fullUrl = url;

    if (queryParams) {
        const queryString = Object.entries(queryParams)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');

        fullUrl = `${url}?${queryString}`;
    }

    window.location.href = fullUrl;
};

/**
 * 打开链接 并且新开窗口
 * @param url
 */
export const openUrl = (url: string) => {
    window.open(url);
};

/**
 * 获取当前域名
 */
export const getRootURL = () => {
    return `${window.location.protocol}//${window.location.host}`
}

/**
 * 处理内容关键词高亮
 * @param text
 * @param words
 * @returns
 */
export const getHighlightText = (text: string, words: string | string[]): string => {
    if (!text || !words) {
        return text;
    }
    const regex = new RegExp(
        isArray(words)
            ? words.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")).join("|")
            : words.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
        "gi"
    );
    return text.replace(regex, match => `<span class="highlight-bg">${match}</span>`);
};

export const disabledTestGroupMessage = (itemObj: any) => {
    let test_group_rounds=itemObj.message_entity.test_group_rounds
    if(itemObj.message_entity.entity_type=='onboarding_poll' ){
        return false
    }else{
        if(test_group_rounds==null){
            return false
        }else{
            if(test_group_rounds.length>0){
                return true
            }else{
                return false
            }
        }
    }
    
}


Vue.prototype.$primaryColor = primaryColor;
Vue.prototype.$defaultCover = defaultCover;
Vue.prototype.$defaultAvatar = defaultAvatar;
Vue.prototype.$defaultUserAvatar = defaultUserAvatar;
Vue.prototype.$doCopy = doCopy;
Vue.prototype.$dateFormat = filters.dateFormat;
Vue.prototype.$handleBr = handleBr;
Vue.prototype.$removeHTMLTag = removeHTMLTag;
Vue.prototype.$timeFormat = timeFormat;
Vue.prototype.$disabledMessageEntity = disabledMessageEntity;
Vue.prototype.$disabledTestGroupMessage = disabledTestGroupMessage;
Vue.prototype.$getQiniuThumbnail = getQiniuThumbnail;
Vue.prototype.$getVideoThumbnail = getVideoThumbnail;
Vue.prototype.$goUrl = goUrl;
Vue.prototype.$openUrl = openUrl;

/**
 * 全局注册过滤器
 */
Object.keys(filters).forEach((key: string) => {
    Vue.filter(key, (filters as any)[key]);
});
