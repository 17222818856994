import { RouteConfig } from 'vue-router'

export const observerRoutes: RouteConfig[] = [
    {
        path: '/observer/:shareUuid',
        name: 'ObserverLive',
        component: () => import('@/views/observer/ObserverLive.vue')
    },
    {
        path: '/observer/:shareUuid/refuse',
        name: 'ObserverRefuse',
        component: () => import('@/views/observer/ObserverRefuse.vue')
    }
]
