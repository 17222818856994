





































import { computed, defineComponent, ref } from '@vue/composition-api'
import { useSettingStore } from '@/pinia/modules/setting'
import { LocaleType } from '@/interface/common'
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui'

export default defineComponent({
    props: {
        size: {
            type: String,
            default: 'default'
        },
        placement: {
            type: String,
            default: 'right'
        }
    },
    components: {
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem
    },
    setup(props, { root }) {
        const settingStore = useSettingStore()
        const localeOptions = ref([
            {
                name: 'English',
                value: LocaleType.en_US
            },
            {
                name: '中文简体',
                value: LocaleType.zh_CN
            },
            {
                name: '中文繁体',
                value: LocaleType.zh_TW
            },
        ])
        const locale = ref(settingStore.locale)
        const localeName = computed(() => {
            return localeOptions.value.find((v) => v.value == locale.value)?.name
        })
        const onLocaleChange = (item: any) => {
            if (item.value == locale.value) {
                return
            }
            locale.value = item.value
            settingStore.setLocale(item.value)
        }
        return {
            locale,
            localeName,
            localeOptions,
            onLocaleChange
        }
    }
})
