









import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
    props: {
        src: String,
        name: String,
        size: {
            type: Number,
            default: 40
        },
        fontSize: {
            type: Number,
            default: 14
        }
    },
    setup(props) {
        const nameStr = computed(() => {
            const first = props.name?.substr(0, 1) || ''
            return first.toUpperCase() || ''
        })
        return {
            nameStr
        }
    }
})
